import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useStaticQuery, graphql } from "gatsby"
import CollapseContent from './helpers/collapseContent';

function CourseListing({ degree }) {

    const data = useStaticQuery(graphql`
        query CourseListing {
            edu: allMdx(filter: {fileAbsolutePath: {regex: "/(education-courses)/"  }}
            sort: {fields: frontmatter___number, order: ASC}) {
                nodes {
                    frontmatter {
                      number
                      title
                    }
                    body
                }
            }
            psych: allMdx(filter: {fileAbsolutePath: {regex: "/(psychology-courses)/"  }}
            sort: {fields: frontmatter___number, order: ASC}) {
                nodes {
                    frontmatter {
                      number
                      title
                    }
                    body
                }
            }
            mba: allMdx(filter: {fileAbsolutePath: {regex: "/(business-courses)/"  }}
            sort: {fields: frontmatter___number, order: ASC}) {
                nodes {
                    frontmatter {
                      number
                      title
                    }
                    body
                }
            }
        }
    `)

    if (degree ==="edu") {
        return (
            <Row id="courses" className="white-bg mb-5 align-items-start row-cols-lg-3 row-cols-md-2 row-cols-1 gx-md-5 px-2 px-0">
                    {
                        data.edu.nodes.map(( node, index) => {
                            return (
                                <Col id={index++} className="mb-4">                    
                                    <Row className="border rounded h-100">
                                        <Col>
                                            <Row className="blue-heading border-bottom">
                                                <Col className="p-3 m-1">
                                                    <h6>{ node.frontmatter.number }</h6>
                                                    <h5>{ node.frontmatter.title }</h5>
                                                </Col>
                                            </Row>
                                            <Row className="degree-info py-3 px-2">
                                                <Col>
                                                    <CollapseContent index={index} content={node.body} closeText="Show Less" openText="Show More" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }) 
                    }
            </Row>
        )
    }
    if (degree === "psych") {
        return (
            <Row id="courses" className="row white-bg mb-5 align-items-start row-cols-lg-3 row-cols-md-2 row-cols-1 gx-md-5 px-2 px-lg-0">
                    {
                        data.psych.nodes.map(( node, index) => {
                            return (
                                <Col id={index++} className="mb-4">                    
                                    <Row className="border rounded h-100">
                                        <Col>
                                            <Row className="blue-heading border-bottom">
                                                <Col className="p-3 m-1">
                                                    <h6>{ node.frontmatter.number }</h6>
                                                    <h5>{ node.frontmatter.title }</h5>
                                                </Col>
                                            </Row>
                                            <Row className="degree-info py-3 px-2">
                                                <Col>
                                                    <CollapseContent index={index} content={node.body} closeText="Show Less" openText="Show More" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }) 
                    }
            </Row>
        )
    } 
    if (degree === "mba") {
        return (
            <Row id="courses" className="row white-bg mb-5 align-items-start row-cols-lg-3 row-cols-md-2 row-cols-1 gx-md-5 px-2 px-lg-0">
                    {
                        data.mba.nodes.map(( node, index) => {
                            return (
                                <Col id={index} className="mb-4">                    
                                    <Row className="border rounded h-100">
                                        <Col>
                                            <Row className="blue-heading border-bottom">
                                                <Col className="p-3 m-1">
                                                    <h6>{ node.frontmatter.number }</h6>
                                                    <h5>{ node.frontmatter.title }</h5>
                                                </Col>
                                            </Row>
                                            <Row className="degree-info py-3 px-2">
                                                <Col>
                                                    <CollapseContent index={index} content={node.body} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }) 
                    }
            </Row>
        )
    }
}

export default CourseListing
