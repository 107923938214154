import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import Button from "react-bootstrap/Button";

import "./styles/licensureMap.scss";

const LicensureMap = ({ degree }) => {
  const [show, toggleShow] = React.useState(true);
  // prettier-ignore
  if(degree === "mcp") {
        return (
            <Container fluid="lg">
                <div id="licensure_map" className="my-md-5 my-4 px-2 px-lg-0">
                    <Row className="justify-content-between align-items-center p-md-5 p-3 grey-bg">
                        <Col lg={4} xl={5} sm={12} className="mb-4">
                            <h3 className="mb-4">State Licensure</h3>
                            <div className="mb-4">
                                <p><strong>The Meridian student body is global. Students in the US can seek educational eligibility for licensure in multiple states and for multiple license types.</strong> Map is for illustrative purposes. For detailed license information per state, click below. In another state or region not listed below? Contact the Admissions Team for more information.</p>
                            </div>
                            <Link to="/admissions/consumer-information/state-licensure-eligibility" className="btn btn-primary">STATE LICENSURE DETAILS  <svg width="7" height="11" viewBox="0 0 7 11" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"><path d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z" fill="#FFFFFF"/></svg></Link>
                        </Col>
                        <Col lg={8} xl={6} sm={12} className="mb-4">
                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/usa-state-licensure-map-mcp-v05202024.svg" alt="meridian university is licensure map" className="img-fluid" />
                        </Col>
                        <Col xs={12} className="p-4 grey-bg">
                            <Row>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>Alaska</h5>
                                            <h6>LPC and MFT</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>Alabama</h5>
                                            <h6>MFT</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>Arizona</h5>
                                            <h6>LPC and MFT</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>California</h5>
                                            <h6>MFT and LPCC</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>Hawaii</h5>
                                            <h6>MFT and MHC</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>Illinois</h5>
                                            <h6>MFT</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>Maine</h5>
                                            <h6>MFT and LPC</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>Massachusetts</h5>
                                            <h6>MFT</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>Pennsylvania</h5>
                                            <h6>LPC and MFT</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>Tennessee</h5>
                                            <h6>LPC</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Row className="justify-content-between">
                                        <Col xs={1} className="px-1">
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg" alt="meridian university is licensure map" className="img-fluid" />
                                        </Col>
                                        <Col xs={11}>
                                            <h5>Texas</h5>
                                            <h6>LPC</h6>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {/*<Col xs="12" className="d-md-none grey-bg">
                            <Button variant="link" onClick={() => toggleShow(!show)}>
                                <span className={show ? 'd-inline-block me-3 ms-1' : 'd-none'}><svg width="16" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.9993 6.94438H6.83268V11.111C6.83268 11.5694 6.45768 11.9444 5.99935 11.9444C5.54102 11.9444 5.16602 11.5694 5.16602 11.111V6.94438H0.999349C0.541016 6.94438 0.166016 6.56938 0.166016 6.11104C0.166016 5.65271 0.541016 5.27771 0.999349 5.27771H5.16602V1.11104C5.16602 0.65271 5.54102 0.27771 5.99935 0.27771C6.45768 0.27771 6.83268 0.65271 6.83268 1.11104V5.27771H10.9993C11.4577 5.27771 11.8327 5.65271 11.8327 6.11104C11.8327 6.56938 11.4577 6.94438 10.9993 6.94438Z" fill="#323337"/></svg></span>
                                <span className={show ? 'd-none' : 'd-inline-block me-3 ms-1'}><svg id="Layer_1" data-name="Layer 1" width="12" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6"><path d="m5,1.67H.83c-.46,0-.83-.38-.83-.83s.37-.83.83-.83h4.17" fill="#323337"/><path d="m5,0h5.83c.46,0,.83.38.83.83s-.38.83-.83.83h-5.83" fill="#323337"/></svg></span>
                                {show ? 'Show more' : 'Show less'}
                            </Button>
                        </Col>*/}
                    </Row>
                </div>
            </Container>
        )
    }
  if (degree === "psyd") {
    return (
      <Container fluid="lg">
        <div id="licensure_map" className="my-md-5 my-4 px-2 px-lg-0">
          <Row className="justify-content-between align-items-center p-md-5 p-3 grey-bg">
            <Col lg={4} xl={5} sm={12} className="mb-4">
              <h3 className="mb-4">State Licensure</h3>
              <div className="mb-4">
                <p>
                  <strong>
                    The Meridian student body is global. Students in the US can
                    seek educational eligibility for licensure in multiple
                    states and for multiple license types.
                  </strong>{" "}
                  Map is for illustrative purposes. For detailed license
                  information per state, click below. In another state or region
                  not listed below? Contact the Admissions Team for more
                  information.
                </p>
              </div>
              <Link
                to="/admissions/consumer-information/state-licensure-eligibility"
                className="btn btn-primary"
              >
                STATE LICENSURE DETAILS{" "}
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="#FFFFFF"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </Link>
            </Col>
            <Col lg={8} xl={6} sm={12} className="mb-4">
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/usa-state-licensure-map-psyd-v05202024.svg"
                alt="meridian university is licensure map"
                className="img-fluid"
              />
            </Col>
            <Col xs={12} className="p-4 grey-bg">
              <Row>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Alabama</h5>
                      <h6>MFT</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Alaska</h5>
                      <h6>MFT and LPC</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Arizona</h5>
                      <h6>MFT</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>California</h5>
                      <h6>MFT, LPCC, and Psychologist</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Hawaii</h5>
                      <h6>MFT, MHC, and Psychologist</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Illinois</h5>
                      <h6>MFT</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Maine</h5>
                      <h6>MFT and LPC</h6>
                    </Col>
                  </Row>
                </Col>
                
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Pennsylvania</h5>
                      <h6>LPC</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Texas</h5>
                      <h6>MFT and PSY</h6>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
  if (degree === "phd") {
    return (
      <Container fluid="lg">
        <div id="licensure_map" className="my-md-5 my-4 px-2 px-lg-0">
          <Row className="justify-content-between align-items-center p-md-5 p-3 grey-bg">
            <Col lg={4} xl={5} sm={12} className="mb-4">
              <h3 className="mb-4">State Licensure</h3>
              <div className="mb-4">
                <p>
                  <strong>
                    The Meridian student body is global. Students in the US can
                    seek educational eligibility for licensure in multiple
                    states and for multiple license types.
                  </strong>{" "}
                  Map is for illustrative purposes. For detailed license
                  information per state, click below. In another state or region
                  not listed below? Contact the Admissions Team for more
                  information.
                </p>
              </div>
              <Link
                to="/admissions/consumer-information/state-licensure-eligibility"
                className="btn btn-primary"
              >
                STATE LICENSURE DETAILS{" "}
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="#FFFFFF"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </Link>
            </Col>
            <Col lg={8} xl={6} sm={12} className="mb-4">
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/usa-state-licensure-map-phd-v05202024.svg"
                alt="meridian university is licensure map"
                className="img-fluid"
              />
            </Col>
            <Col xs={12} className="p-4 grey-bg">
              <Row>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Alaska</h5>
                      <h6>MFT and LPC</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Arizona</h5>
                      <h6>MFT</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>California</h5>
                      <h6>MFT, LPCC, and Psychologist </h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Hawaii</h5>
                      <h6>MFT, MHC, and Psychologist</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Illinois</h5>
                      <h6>MFT</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Pennsylvania</h5>
                      <h6>LPC</h6>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Row className="justify-content-between">
                    <Col xs={1} className="px-1">
                      <img
                        src="https://web-cdn.meridianuniversity.edu/site-content-images/degree-pages/state-licensure-marker.svg"
                        alt="meridian university is licensure map"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={11}>
                      <h5>Texas</h5>
                      <h6>MFT and PSY</h6>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {/*<Col xs="12" className="d-md-none grey-bg">
                            <Button variant="link" onClick={() => toggleShow(!show)}>
                                <span className={show ? 'd-inline-block me-3 ms-1' : 'd-none'}><svg width="16" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.9993 6.94438H6.83268V11.111C6.83268 11.5694 6.45768 11.9444 5.99935 11.9444C5.54102 11.9444 5.16602 11.5694 5.16602 11.111V6.94438H0.999349C0.541016 6.94438 0.166016 6.56938 0.166016 6.11104C0.166016 5.65271 0.541016 5.27771 0.999349 5.27771H5.16602V1.11104C5.16602 0.65271 5.54102 0.27771 5.99935 0.27771C6.45768 0.27771 6.83268 0.65271 6.83268 1.11104V5.27771H10.9993C11.4577 5.27771 11.8327 5.65271 11.8327 6.11104C11.8327 6.56938 11.4577 6.94438 10.9993 6.94438Z" fill="#323337"/></svg></span>
                                <span className={show ? 'd-none' : 'd-inline-block me-3 ms-1'}><svg id="Layer_1" data-name="Layer 1" width="12" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6"><path d="m5,1.67H.83c-.46,0-.83-.38-.83-.83s.37-.83.83-.83h4.17" fill="#323337"/><path d="m5,0h5.83c.46,0,.83.38.83.83s-.38.83-.83.83h-5.83" fill="#323337"/></svg></span>
                                {show ? 'Show more' : 'Show less'}
                            </Button>
                        </Col>*/}
          </Row>
        </div>
      </Container>
    );
  }
};

export default LicensureMap;
