import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useStaticQuery, graphql } from "gatsby"
import { Accordion } from 'react-bootstrap'
import { MDXRenderer } from 'gatsby-plugin-mdx'

function FacultyListing({ degree }) {

    const data = useStaticQuery(graphql`
        query FacultyMembersListing {
            edu: allMdx(filter: {fileAbsolutePath: {regex: "/(education-faculty-members)/"  }}
            sort: {fields: frontmatter___title, order: ASC}) {
                nodes {
                    frontmatter {
                      title
                    }
                    body
                }
            }
            psych: allMdx(filter: {fileAbsolutePath: {regex: "/(psychology-faculty-members)/"  }}
            sort: {fields: frontmatter___title, order: ASC}) {
                nodes {
                    frontmatter {
                      title
                    }
                    body
                }
            }
            mba: allMdx(filter: {fileAbsolutePath: {regex: "/(business-faculty-members)/"  }}
            sort: {fields: frontmatter___title, order: ASC}) {
                nodes {
                    frontmatter {
                      title
                    }
                    body
                }
            }
        }
    `)

    if (degree ==="edu") {
        return (
            <Row id="faculty" className="row white-bg mb-5 align-items-start">
                <Col lg={6} className="d-none d-lg-block">
                    {
                        data.edu.nodes.map(( node, index) => {
                            if (index % 2 === 0) {
                                return (
                                    <Accordion className="eduFacultyAccordion">
                                        <Accordion.Item eventKey={index++} id={index++}>
                                            <Accordion.Header>{ node.frontmatter.title }</Accordion.Header>
                                            <Accordion.Body>
                                                <MDXRenderer>
                                                    {node.body}
                                                </MDXRenderer>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )
                            }
                            return null;
                        }) 
                    }
                </Col>
                <Col lg={6} className="d-none d-lg-block">
                    {
                        data.edu.nodes.map(( node, index) => {
                            if (index % 2 === 1) {
                                return (
                                    <Accordion className="eduFacultyAccordion">
                                        <Accordion.Item eventKey={index++} id={index++}>
                                            <Accordion.Header>{ node.frontmatter.title }</Accordion.Header>
                                            <Accordion.Body>
                                                <MDXRenderer>
                                                    {node.body}
                                                </MDXRenderer>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )
                            }
                            return null;
                        }) 
                    }
                </Col>
                <Col className="d-lg-none">
                    {
                        data.edu.nodes.map(( node, index) => (
                            <Accordion className="eduFacultyAccordion">
                                <Accordion.Item eventKey={index++} id={index++}>
                                    <Accordion.Header>{ node.frontmatter.title }</Accordion.Header>
                                    <Accordion.Body>
                                        <MDXRenderer>
                                            {node.body}
                                        </MDXRenderer>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )) 
                    }
                </Col>
            </Row>
        )
    }
    if (degree === "psych") {
        return (
            <Row id="faculty" className="row white-bg mb-5 align-items-start">
                <Col lg={6} className="d-none d-lg-block">
                    {
                        data.psych.nodes.map(( node, index) => {
                            if (index % 2 === 0) {
                                return (
                                    <Accordion className="eduFacultyAccordion">
                                        <Accordion.Item eventKey={index++} id={index++}>
                                            <Accordion.Header>{ node.frontmatter.title }</Accordion.Header>
                                            <Accordion.Body>
                                                <MDXRenderer>
                                                    {node.body}
                                                </MDXRenderer>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )
                            }
                            return null;
                        }) 
                    }
                </Col>
                <Col lg={6} className="d-none d-lg-block">
                    {
                        data.psych.nodes.map(( node, index) => {
                            if (index % 2 === 1) {
                                return (
                                    <Accordion className="eduFacultyAccordion">
                                        <Accordion.Item eventKey={index++} id={index++}>
                                            <Accordion.Header>{ node.frontmatter.title }</Accordion.Header>
                                            <Accordion.Body>
                                                <MDXRenderer>
                                                    {node.body}
                                                </MDXRenderer>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )
                            }
                            return null;
                        }) 
                    }
                </Col>
                <Col className="d-lg-none">
                    {
                        data.psych.nodes.map(( node, index) => (
                            <Accordion className="eduFacultyAccordion">
                                <Accordion.Item eventKey={index++} id={index++}>
                                    <Accordion.Header>{ node.frontmatter.title }</Accordion.Header>
                                    <Accordion.Body>
                                        <MDXRenderer>
                                            {node.body}
                                        </MDXRenderer>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )) 
                    }
                </Col>
            </Row>
        )
    } 
    if (degree === "mba") {
        return (
            <Row id="faculty" className="row white-bg mb-5 align-items-start">
                <Col lg={6} className="d-none d-lg-block">
                    {
                        data.mba.nodes.map(( node, index) => {
                            if (index % 2 === 0) {
                                return (
                                    <Accordion className="eduFacultyAccordion">
                                        <Accordion.Item eventKey={index++} id={index++}>
                                            <Accordion.Header>{ node.frontmatter.title }</Accordion.Header>
                                            <Accordion.Body>
                                                <MDXRenderer>
                                                    {node.body}
                                                </MDXRenderer>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )
                            }
                            return null;
                        }) 
                    }
                </Col>
                <Col lg={6} className="d-none d-lg-block">
                    {
                        data.mba.nodes.map(( node, index) => {
                            if (index % 2 === 1) {
                                return (
                                    <Accordion className="eduFacultyAccordion">
                                        <Accordion.Item eventKey={index++} id={index++}>
                                            <Accordion.Header>{ node.frontmatter.title }</Accordion.Header>
                                            <Accordion.Body>
                                                <MDXRenderer>
                                                    {node.body}
                                                </MDXRenderer>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )
                            }
                            return null;
                        }) 
                    }
                </Col>
                <Col className="d-lg-none">
                    {
                        data.mba.nodes.map(( node, index) => (
                            <Accordion className="eduFacultyAccordion">
                                <Accordion.Item eventKey={index++} id={index++}>
                                    <Accordion.Header>{ node.frontmatter.title }</Accordion.Header>
                                    <Accordion.Body>
                                        <MDXRenderer>
                                            {node.body}
                                        </MDXRenderer>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )) 
                    }
                </Col>
            </Row>
        )
    }
}

export default FacultyListing
