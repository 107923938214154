import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SSRProvider from 'react-bootstrap/SSRProvider'
import ReactPlayer from 'react-player'

import AcademicStructure from '../components/academicStructure'
import AccredAndFinAid from '../components/accredAndFinAid'
import Celebrating30Years from'../components/celebrating30Years'
import ContactForm from "../components/forms/contactFormStyled"
import CourseListing from '../components/courseListing'
import DefaultHeader from '../components/default-header'
import FacultyListing from '../components/facultyListing'
import Footer from '../components/footer'
import GdprPanel from '../components/gdpr'
import { ImpactScholarshipBottomCTA, ImpactScholarshipTopCTA, PSPPScholarship } from '../components/impactScholarshipCTA'
import LearningFormats from '../components/learningFormats'
import LicensureMap from '../components/licensureMap'
import Navigation from '../components/navigation'
import PageQuote from '../components/quote'
import Seo from '../components/seo'
import SpeakWithAdmissions from '../components/speakWithAdmissions'

import '../styles/degree.scss'
import NewsletterSignup from '../components/newsletterSignUp'


export default function DegreePage({ data }) {

    const post = data.mdx
    const shortcodes = {
        LicensureMap,
        AcademicStructure,
        LearningFormats,
        AccredAndFinAid,
        PageQuote,
        SpeakWithAdmissions,
        ImpactScholarshipBottomCTA,
        ImpactScholarshipTopCTA,
        ContactForm,
        FacultyListing,
        CourseListing,
        Celebrating30Years,
        PSPPScholarship,
        ReactPlayer,
        Container,
        Row,
        Col,
    }

    return (      
        <>  
        <SSRProvider>
            <Navigation></Navigation>
            <DefaultHeader headline={post.frontmatter.headline} heroBackground={post.frontmatter.heroBackground} heroMobileBackground={post.frontmatter.heroMobileBackground} subHeadline={post.frontmatter.subheadline}></DefaultHeader>
            <MDXProvider components={shortcodes}>
                <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>      
            <Footer/>
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage} 
    />
)

export const query = graphql`
    query($slug: String!) {
        mdx(slug: { eq: $slug }) {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                headerType
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
                menuType
            }
            body
        }
    }   
`
